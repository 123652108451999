/* eslint-disable prettier/prettier */
import React from 'react'
import PropTypes from 'prop-types'
import LayoutListView from '../../components/LayoutListView'
import '../../styles/fees.css'
import Block from '../../components/Block'

const Fees = ({ location }) => (
  <LayoutListView path={location.pathname} configID="61xil3hdTj9sQHXWdT2TEr">
    <div id="pricing" className="comparison">
      <table>
        <thead>
          <tr>
            <th className="compare-heading">&nbsp;</th>
            <th className="compare-heading">
              <h3>Members</h3>
            </th>
            <th className="compare-heading">
              <h3>Associates</h3>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td />
            <td colSpan="2">
              <strong>LEVEL 1</strong> - Gross revenue less than $500,000
            </td>
          </tr>
          <tr className="compare-row">
            <td>
              <strong>LEVEL 1</strong> - Gross revenue less than $500,000
            </td>
            <td>$1,000</td>
            <td>$650</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan="2">
              <strong>LEVEL 2</strong> - Gross revenue between $500,000 and $3,000,000
            </td>
          </tr>
          <tr>
            <td>
              <strong>LEVEL 2</strong> - Gross revenue between $500,000 and $3,000,000
            </td>
            <td>$2,000</td>
            <td>$1,250</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan="2">
              <strong>LEVEL 3</strong> - Gross revenue between $3M and $10,000,000
            </td>
          </tr>
          <tr className="compare-row">
            <td>
              <strong>LEVEL 3</strong> - Gross revenue between $3M and $10,000,000
            </td>
            <td>$4,000</td>
            <td>$2,600</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan="2">
              <strong>LEVEL 4</strong> - Gross revenue above $10,000,000
            </td>
          </tr>
          <tr>
            <td>
              <strong>LEVEL 4</strong> - Gross revenue above $10,000,000
            </td>
            <td>$6,000</td>
            <td>$3,600</td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td colSpan="2" />


          </tr>
          <tr>
            <td>&nbsp;</td>
            <td />
            <td />
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td colSpan="2">
              <strong>LEVEL B</strong> - Bible Colleges
            </td>
          </tr>
          <tr className="compare-row">
            <td>
              <strong>LEVEL B</strong> - Bible Colleges
            </td>
            <td>$500</td>
            <td>$500</td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td colSpan="2">
              <strong>LEVEL C</strong> - Churches
            </td>
          </tr>
          <tr>
            <td>
              <strong>LEVEL C</strong> - Churches
            </td>
            <td />
            <td>$250</td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td colSpan="2">
              <strong>LEVEL S</strong> - Schools
            </td>
          </tr>
          <tr className="compare-row">
            <td>
              <strong>LEVEL S</strong> - Schools
            </td>
            <td />
            <td>$1000</td>
          </tr>
        </tbody>
      </table>

    </div>
    <br />
    <h3>Additional Fees</h3>
    <Block contentfulID="3T6AMd33B6js3tIVhUe14B" hLevel="2" />
  </LayoutListView>
)

export default Fees

Fees.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
